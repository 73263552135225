import React, { useState } from "react"
import { graphql } from 'gatsby'

import LazySizes from '../components/layout/lazySizes'

import SEO from "../components/layout/seo"
import '../components/pages/index/index.scss'
import TransitionLink from 'gatsby-plugin-transition-link'
import Transition, { exitTransition, entryTransition} from '../components/transition/transition'

import Masonry from 'react-masonry-css'

const config = {
  breakpointCols: {
    default: 4,
    1100: 3,
    700: 2,
    500: 1
  },
  className: "my-masonry-grid",
  columnClassName: "my-masonry-grid_column",
}

const Header = () => {
  return(
    <div className="header">
      <h1>Pararaum</h1>
    </div>
  )
}

const Lay = ({posts }) => {
  

  return(
    <div>
        <Masonry {...config} >
  
        {
          posts.map((post, i) => {

            const gallery = post.projectPage.gallery
            const thumbnail = post.projectPage.thumbnail

            if(!post || typeof thumbnail === 'undefined' || typeof gallery === 'undefined' || gallery == null) return false

            const image = thumbnail ? thumbnail : gallery[0]
            
            if(!image.mediaDetails) return false

            const sizes = image.mediaDetails.sizes
            if(!sizes) return false
            const aspectRatio = sizes[sizes.length-1].width/sizes[sizes.length-1].height
            let className = ""
            let forcedAspectRatio = 1
            if(aspectRatio === 1){
              className = "square"
            }else if(aspectRatio <= 1){
              className = "portrait"
              forcedAspectRatio = 0.707 
            }else{
              className = "landscape"
              forcedAspectRatio = 1.414
            }

            return(
              <div key={i} className={`project ${className}`}>
                <TransitionLink 
                to={post.slug}
                exit={exitTransition}
                entry={entryTransition}>
                  <div className="projects__wrapper" >
                    <LazySizes image={image} forcedAspectRatio={forcedAspectRatio}/>
                  </div>
                </TransitionLink>
              </div>
            )
          })
        }
        </Masonry>
    </div>
  )
}
export default ({ data }) => {

  const posts = data.wpgraphql.posts.nodes;

  const [ state ] = useState({
    headerInView: true
  })

  return (
    <div className="page__index">
    
      <SEO title="Home" />

      <Transition>

        <Header scrollTop={state.headerInView}/>
        <div className="projects" id="projects">
            <Lay posts={posts} />
        </div>
      </Transition>
    </div>
  )
}

export const indexQuery = graphql`
  query indexQuery {
    wpgraphql{
      categories {
        nodes {
          slug
          name
        }
      }
      posts(first: 200) {
        nodes {
          title
          slug
          projectPage {
            thumbnail {
              mediaDetails{
                width
                height
                sizes{
                  sourceUrl
                  width
                  height
                }
              }
            }
            gallery {
              mediaDetails{
                width
                height
                sizes{
                  sourceUrl
                  width
                  height
                }
              }
            }
            subtitle
            titleExtended
            sections{
              ... on WPGraphQL_Post_Projectpage_Sections_Credits {
                list {
                  label
                  text
                }
              }
              ... on WPGraphQL_Post_Projectpage_Sections_Paragraph {
                text
              }
            }
          }
        }
      }
    }
  }
`